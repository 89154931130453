var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-center"},[_c('div',{staticClass:"title_box"},[_c('div',{staticClass:"title_txt"},[_vm._v("订单中心")]),_c('div',{staticClass:"refresh"},[_c('i',{staticClass:"icon"}),_c('div',{staticClass:"refresh_txt",on:{"click":_vm.getOrderList}},[_vm._v("刷新")])])]),_vm._l((_vm.orderlist),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.orderlist.length != 0),expression:"orderlist.length != 0"}],key:index,staticClass:"order_data"},[_c('div',{staticClass:"data_top_box"},[_c('div',{staticClass:"serial_box"},[_vm._v(" 订单编号: "),_c('span',{staticClass:"color_txt"},[_vm._v(" "+_vm._s(item.orderNumber)+" ")])]),_c('div',{staticClass:"place_date_box"},[_vm._v("下单时间")]),_c('div',{staticClass:"number_box"},[_vm._v("数量")]),_c('div',{staticClass:"money_box"},[_vm._v("金额")]),_c('div',{staticClass:"txt"},[_vm._v(_vm._s(item.payStatus == 1 ? "未完成" : "已完成"))])]),_c('div',{staticClass:"data_bottom_box"},[_c('div',{staticClass:"list"},_vm._l((item.productInfos),function(item1,index){return _c('div',{key:index,staticClass:"item_box_left"},[_c('div',{staticClass:"img_name_box"},[_c('img',{staticClass:"item_img",attrs:{"src":item1.productFiles
                  ? _vm.$store.state.onlineBasePath + item1.productFiles
                  : require('assets/images/no-shop-img80.png'),"alt":""}}),_c('div',{staticClass:"item_name"},[_vm._v(_vm._s(item1.productName))])]),_c('div',{staticClass:"item_date"},[_vm._v(_vm._s(item.createTime.substring(0, 10)))]),_c('div',{staticClass:"item_sum"},[_vm._v(_vm._s(item1.productNum))]),_c('div',{staticClass:"item_money"},[_vm._v("¥ "+_vm._s(item1.productPrices))])])}),0),_c('div',{staticClass:"item_box_right"},[_c('div',{staticClass:"order_money"},[_vm._v(" ¥ "+_vm._s(item.payPrice == null ? item.orderPrice : item.payPrice)+" ")]),_c('div',{staticClass:"order_details",on:{"click":function($event){_vm.$router.push({
              path: 'orderDetails',
              query: {
                item: JSON.stringify(item),
                page: _vm.page,
              },
            })}}},[_vm._v(" [查看订单详情] ")]),_c('div',{staticClass:"order_delete",on:{"click":function($event){return _vm.prompt(item.orderNumber)}}},[_vm._v("删除")]),(item.payStatus != 1)?_c('div',{staticClass:"order_comments",on:{"click":function($event){_vm.$router.push({
              path: 'orderComments',
              query: {
                item: JSON.stringify(item),
                page: _vm.page,
              },
            })}}},[_vm._v(" 评论 ")]):_vm._e()])])])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.orderlist.length != 0),expression:"orderlist.length != 0"}],staticClass:"pagination"},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","prev-text":"上一页","next-text":"下一页","page-size":_vm.limit,"current-page":_vm.page,"total":_vm.total},on:{"prev-click":_vm.lastPageChange,"next-click":_vm.nextPageChange,"current-change":_vm.currentChange}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowImg),expression:"isShowImg"}],staticClass:"noOrder"},[_c('img',{attrs:{"src":require('assets/images/no-shop.png'),"alt":""}}),_vm._v(" 暂无订单 ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }